// This is the scss entry file
import "../styles/index.scss";
import $ from "jquery/dist/jquery";
import "bootstrap/dist/js/bootstrap.bundle";
var bootstrap = require("bootstrap");
import "htmx.org";
window.htmx = require('htmx.org');
import cookie from "cookie_js/dist/cookie.umd";
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import Toastify from "toastify-js";
Sharer = require("sharer.js");
window.Swal = require('sweetalert2');

// We can import other JS file as we like
import "../components/sidebar";


htmx.config.useTemplateFragments = true;

// Use the 'ready' event to set the initial scroll position
htmx.on('ready', function() {
    var scrollPosition = window.pageYOffset;
    htmx.set('scroll', scrollPosition);
});
    
    // Use the 'beforeRequest' event to update the scroll position before making the request
htmx.on('beforeRequest', function() {
    var scrollPosition = window.pageYOffset;
    htmx.set('scroll', scrollPosition);
});
    
    // Use the 'afterUpdate' event to set the scroll position after the update
htmx.on('afterUpdate', function() {
    var scrollPosition = htmx.get('scroll');
    window.scrollTo(0, scrollPosition);
 });


var theme = cookie.get("theme");
if (theme == "dark") {
    $("body").addClass("dark-theme");
} 

var mm = window.matchMedia("(prefers-color-scheme: dark)").matches;
if (mm && !theme) {
    cookie.set("theme","dark", {path: "/" });
    $("body").addClass("dark-theme");
}

// SET Dark Mode
$("#mode").on("click", function () {
    $("body").toggleClass("dark-theme");

    if (theme == "light") {
        cookie.set("theme","dark", {path: "/" });
    } 
    if (theme == "dark") {
        cookie.set("theme","light", {path: "/" });
    }
    theme = cookie.get("theme");
  });

var cheez = ['خط', 'غزل', 'لطیفہ', 'داستان', 'قصہ', 'شعر', 'نظم', 'گانا', 'مضمون', 'بلاگ']
var loag = ['اپنے محبوب','اپنی خالہ', 'اپنی محبوبہ', 'اپنے دوست', 'اپنی پھوپھی', 'اپنی سہیلی', 'اپنے دشمن', 'پورے محلے', 'سارے خاندان',
            'اپنے یاروں', 'اپنے استاد', 'قطب الدین', 'فلانے ٹمکانے', 'سارے جہان', 'کاشف', 'اپنے شوہر', 'اپنی بیوی', 'اپنے گوالے',             
]
var w1 = " اردو میں ";
var w2 = " لکھیں ";
var w3 = " اور ";
var w4 = " کو بھیجیں ";





// htmx.on("#menu-button", "click", function(evt) {
//     htmx.findAll(".btn-circle").forEach(function(p) {
//         htmx.toggleClass(p, "d-none");
//     });
// });

// document.btn_menu = document.getElementById("menu-button");

// document.btn_menu.addEventListener('click', async () => {
//     htmx.findAll(".btn-circle").forEach(function(p) {
//         htmx.toggleClass(p, "d-none");
//     });
// });

htmx.on(htmx.find("#menu-button"), "click", function(evt) {
    htmx.findAll(".btn-circle").forEach(function(p) {
        htmx.toggleClass(p, "d-none");
    });
});


if (window.innerWidth < 992) {
    htmx.findAll(".btn-circle").forEach(function(p) {
        htmx.addClass(p, "d-none");

        htmx.on(p, "click", function(evt) {
            htmx.findAll(".btn-circle").forEach(function(p) {
                htmx.toggleClass(p, "d-none");
            });
        });
    });
    
}

if (window.innerWidth > 992) {
    // set tippy placement right
    tippy('[data-tippy-content]', {
        placement: 'right',
        hideOnClick: true,
        duration: 100,

    });
}

htmx.on("htmx:load", function(evt){ 

    // var sharing_menu = htmx.find("#share-menu-btn");


    // const shrey = htmx.on(htmx.find("#share-menu-btn"), "click", function(evt) {
    //     htmx.toggleClass(htmx.find("#sharing-menu"), "d-none");
    // });
    // select #share-menu-btn in plain javascript
    // var btn_menu = document.getElementById("menu-button");
    // check if there is an event listener on the button
    // if there is, remove it

   
    var btn_share = document.getElementById("share-menu-btn");

    if (btn_share!=null) {
        btn_share.addEventListener('click', async () => {
            try {
                await navigator.share(window.shareData);
                Toastify({
                    text: 'کامیابی کے ساتھ شیئر کیا گیا',
                    position: "left",
                    gravity: "bottom",
                    offset: {
                        x: 50, // horizontal axis - can be a number or a string indicating unity. eg: '2em'
                        y: -10, // vertical axis - can be a number or a string indicating unity. eg: '2em'
                    },
                }).showToast();
                // resultPara.textContent = 'کامیابی کے ساتھ شیئر کیا گیا';
            } catch (err) {
                // resultPara.textContent = ``;
            }
        });
    }
    



    // check if home page
    if (window.location.pathname == "/" || window.location.pathname == "/logout/") {

        var yecheez = cheez[Math.floor(Math.random() * cheez.length)];
        var yeloag = loag[Math.floor(Math.random() * loag.length)];
        $("#cheez").html(yecheez);
        $("#loag").html(yeloag);

        function changeH1() {
            
            $("#cheez").addClass("fadeout");

            setTimeout(function () {
                var text=1;
            }, 1800);

            $("#loag").addClass("fadeout");

            yecheez = cheez[Math.floor(Math.random() * cheez.length)];
            while (yecheez == $("#cheez").html()) {
                yecheez = cheez[Math.floor(Math.random() * cheez.length)];
            };
            yeloag = loag[Math.floor(Math.random() * loag.length)];
            while (yeloag == $("#loag").html()) {
                yeloag = loag[Math.floor(Math.random() * loag.length)];
            };
            var text = w1 + yecheez + w2 + w3 + yeloag +  w4;

            setTimeout(function () {
                $("#loag").html(yeloag);
                $("#loag").removeClass("fadeout");
            }, 600);

            setTimeout(function () {
                $("#cheez").html(yecheez);
                $("#cheez").removeClass("fadeout");
            }, 600);
        }

        setInterval(changeH1, 4000); 
    }   

});


// urdu input 
var m = {
    q: "ق",
    w: "و",
    e: "ع",
    r: "ر",
    t: "ت",
    y: "ے",
    u: "ء",
    i: "ی",
    o: "ہ",
    p: "پ",
    a: "ا",
    s: "س",
    d: "د",
    f: "ف",
    g: "گ",
    h: "ح",
    j: "ج",
    k: "ک",
    l: "ل",
    z: "ز",
    x: "ش",
    c: "چ",
    v: "ط",
    b: "ب",
    n: "ن",
    m: "م",
    "`": "ً",
    ",": "،",
    ".": "۔",
    Q: "ْ",
    W: "ّ",
    E: "ٰ",
    R: "ڑ",
    T: "ٹ",
    Y: "َ",
    U: "ئ",
    I: "ِ",
    O: "ۃ",
    P: "ُ",
    A: "آ",
    S: "ص",
    D: "ڈ",
    G: "غ",
    H: "ھ",
    J: "ض",
    K: "خ",
    Z: "ذ",
    X: "ژ",
    C: "ث",
    V: "ظ",
    N: "ں",
    M: "٘",
    "~": "ٍ",
    "?": "؟",
    F: "ٔ",
    L: "ل",
    B: "ب",
    0: "۰",
    1: "۱",
    2: "۲",
    3: "۳",
    4: "۴",
    5: "۵",
    6: "۶",
    7: "۷",
    8: "۸",
    9: "۹",
    " ": " ",
    "ا": "ا",
    "ب": "ب",
    "پ": "پ",
    "ت": "ت",
    "ٹ": "ٹ",
    "ث": "ث",
    "ج": "ج",
    "چ": "چ",
    "ح": "ح",
    "خ": "خ",
    "د": "د",
    "ڈ": "ڈ",
    "ذ": "ذ",
    "ر": "ر",
    "ڑ": "ڑ",
    "ز": "ز",
    "ژ": "ژ",
    "س": "س",
    "ش": "ش",
    "ص": "ص",
    "ض": "ض",
    "ط": "ط",
    "ظ": "ظ",
    "ع": "ع",
    "غ": "غ",
    "ف": "ف",
    "ق": "ق",
    "ک": "ک",
    "گ": "گ",
    "ل": "ل",
    "م": "م",
    "ن": "ن",
    "ں": "ں",
    "و": "و",
    "ؤ": "ؤ",
    "ہ": "ہ",
    "ء": "ء",
    "ی": "ی",
    "ئ": "ئ",
    "ے": "ے",
    "ۃ": "ۃ",
    "آ": "آ",
    "ً": "ً",
    "ٍ": "ٍ",
    "ٰ": "ٰ",
    "ٔ": "ٔ",
    "َ": "َ",
    "ُ": "ُ",
    "ِ": "ِ",
    "ّ": "ّ",
    "ْ": "ْ",
    "٘": "٘",
    "،": "،",
    "۔": "۔",
    "؟": "؟",
    "۰": "۰",
    "۱": "۱",
    "۲": "۲",
    "۳": "۳",
    "۴": "۴",
    "۵": "۵",
    "۶": "۶",
    "۷": "۷",
    "۸": "۸",
    "۹": "۹",
    " ": " ",
};
export default m;
// enable urdu input
htmx.onLoad(function(content) {
    // enable urdu input
    $(".urduinput").each(function () {
        var last = "";
        $(this).bind("input", function () {
          var pos = $(this)[0].selectionEnd;
          var s = $(this).val();
          var isLastPos = pos == s.length;
          if (last == s) return;
          var S = [];
          for (var x = 0; x < s.length; x++) {
            var c = s.charAt(x);
            S.push(m[c] || c);
          }
          $(this).val(S.join(""));
          last = $(this).val();
          if (!isLastPos) {
            $(this)[0].selectionStart = $(this)[0].selectionEnd = pos;
          }
        });
    });

    
    // profile picture preview
    $(document).on('change', '#id_profile_pic', function(e){ 
        
        var file = e.target.files[0];
        if (!file.type.match('image.*')) {
            return;
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function(e) {
            var img = new Image();
            img.src = e.target.result;
            img.onload = function() {
                $('#profile_picture').attr("src", img.src);
            };
        };
    });



    // var btn_share = document.querySelector('#share');
    
    // if (btn_share) {
    //     // Share must be triggered by "user activation"
    //     btn_share.addEventListener('click', async () => {
    //         try {
    //             await navigator.share(window.shareData);
    //             Toastify({
    //                 text: 'کامیابی کے ساتھ شیئر کیا گیا',
    //                 position: "left",
    //                 gravity: "bottom",
    //                 offset: {
    //                     x: 50, // horizontal axis - can be a number or a string indicating unity. eg: '2em'
    //                     y: -10, // vertical axis - can be a number or a string indicating unity. eg: '2em'
    //                 },
    //             }).showToast();
    //             // resultPara.textContent = 'کامیابی کے ساتھ شیئر کیا گیا';
    //         } catch (err) {
    //             // resultPara.textContent = ``;
    //         }
    //     });

    //     // // copy data-url to clipboard on #link-share click
    //     // document.querySelector('#link-share').addEventListener('click', function() {
    //     //     var url = this.getAttribute('data-url');
    //     //     console.log(url);
    //     //     navigator.clipboard.writeText(url).then(function() {
    //     //         Toastify({
    //     //             text: 'کامیابی کے ساتھ لنک کاپی کیا گیا',
    //     //             position: "left",
    //     //             gravity: "bottom",
    //     //             offset: {
    //     //                 x: 50, // horizontal axis - can be a number or a string indicating unity. eg: '2em'
    //     //                 y: -10, // vertical axis - can be a number or a string indicating unity. eg: '2em'
    //     //             },
    //     //         }).showToast();
    //     //         // resultPara.textContent = 'کامیابی کے ساتھ لنک کاپی کیا گیا';
    //     //     }, function(err) {
    //     //         Toastify({
    //     //             text: 'لنک کاپی کرنے میں ناکام',
    //     //             position: "left",
    //     //             gravity: "bottom",
    //     //             // eoor toast
    //     //             backgroundColor: "linear-gradient(to bottom, #902923, #c81b12)",
    //     //             offset: {
    //     //             x: 50, // horizontal axis - can be a number or a string indicating unity. eg: '2em'
    //     //             y: -10, // vertical axis - can be a number or a string indicating unity. eg: '2em'
    //     //             },
    //     //         }).showToast();
    //     //     });
    //     // });
    // }
    
});

